<template>
  <header class="topHeader">
    <nav class="navbar navbar-expand-xl navbar-light " :class="navClass">
      <div class="wrapper">
        <a class="navbar-brand one" :href="path('lighty_share_lighty_share_homepage')">
          <img src="../assets/logo-dark.png" alt="LightyShare" />
        </a>
        <div v-if="(noSearch && user) || isSearch" class="two">
          <SearchForm :active-suggestion="true"></SearchForm>
        </div>
        <div v-else-if="!user">
          <PublicMenu></PublicMenu>
        </div>
        <button v-if="hasBurgerMenu" class="navbar-toggler" type="button" @click="toogleOwnerMenu">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="hasBurgerMenu" class="navbar-nav mr-auto loueurNavSandwich" :class="ownerMenuClass">
          <div class="top-nav-menu">
            <ul>
              <li>
                <a :href="path('lighty_share_user_tableau_de_bord')" :class="isActive('/owner/dashboard')">
                  {{$t('navbar-auth.submenu.0')}}
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_user_mes_reservations_proprietaire')" :class="isActive('/owner/rental')"> {{$t('navbar-auth.submenu.1')}}
                  <span v-if="countNewRentals !== 0" class="badge-notification" style="position: relative;top: -3px;">
                      {{countNewRentals}}
                  </span>
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_user_mes_annonces')" :class="isActive('/owner/listing')">
                  {{$t('navbar-auth.submenu.2')}}
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_user_mes_revenus')" :class="isActive('/owner/revenus')">
                  {{$t('navbar-auth.submenu.3')}}
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_lighty_share_owner_settings')" :class="isActive('/owner-settings')">
                  {{$t('navbar-auth.submenu.4')}}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul v-if="!user" class="navbar-nav auth-menu desktop-only">
          <li class="nav-item">
            <a class="nav-link" href="/connexion">{{$t('navbar.itemLogin.1')}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link user-menu" href="/inscription">{{$t('navbar.itemLogin.2')}}</a>
          </li>
        </ul>
        <AuthNavMenu v-else :notifications="notifications"></AuthNavMenu>
      </div>
    </nav>
  </header>
  <div id="menu-container">
    <div id="menu-wrapper">
      <div id="hamburger-menu"><span></span><span></span><span></span></div>
      <!-- hamburger-menu -->
    </div>
  </div>
  <div v-if="user && bottomMenu" class="cart-page-bottom mobile-only">
    <nav class="navbar">
      <ul class="bottom-menu">
        <li>
          <a href="#">
            <span id="hamburger-menu">
              <img :src="asset('img/svg/explorer-icon.svg')">
            </span>
            <span>{{ $t('navbar-auth.item.5') }}</span>
          </a>
        </li>
        <li>
          <a :href="path('lighty_share_user_mes_reservations_locataire')">
            <span>
              <img :src="asset('img/svg/location-icon.svg')">
            </span>
            <span>{{ $t('navbar-auth.dropdown-item.4') }}</span>
          </a>
        </li>
        <li>
          <a :href="path('lighty_share_user_messagerie_default')">
            <span>
              <img :src="asset('img/svg/message-icon.svg')">
            </span>
            <span>{{ $t('navbar-auth.dropdown-item.1') }}</span>
          </a>
        </li>
        <li>
          <button type="button" class="navbar-toggle collapsed" @click="toogleMenu">
            <span><img :src="asset('img/svg/menu-icon.svg')"></span> <span>Menu</span>
          </button>

        </li>
      </ul>

      <div class="collapse navbar-collapse" id="slide-menu-collapse" :style="displayStyle">
        <div class="profile-name">
          <img v-if="profileThumb" :src="'images-upload/' + $symfony.auth.id + '/profil/' + profileThumb" alt="" class="user-image" />
          <div v-else class="avatar avatar-sm-32 avatar-color-1">
            <div class="initials">{{ initials }}</div>
          </div>
          <span>{{ $symfony.auth.prenom }}</span>
        </div>
        <h4 class="voir-profile-link">
          <a :href="path('my_user_show', {id : $symfony.auth.id})"><span>{{ $t('navbar-auth.dropdown-item.5') }}</span> <img :src="'img/svg/right-arrow-red.svg'" alt=""> </a>
        </h4>
        <ul class="left-menu">
          <li v-if="isAdCreation">
            <a class="mobile-red-button publish-annonce" :href="path('lighty_share_user_listing_creation')"><img :src="asset('img/svg/louez-du-materiel.svg')" alt="">
            {{ $t('navbar-auth.dropdown-item.1') }}
            </a>
          </li>
          <li>
            <a :href="path('lightyshare_share_lighty_share_my_favorites')">
              <img :src="asset('img/svg/fav-icon.svg')" alt="">
            {{ $t('navbar-auth.dropdown-item.2') }} </a>
          </li>
          <li>
            <a :href="path('lighty_share_user_mes_paniers')"><img :src="asset('img/svg/cart-icon.svg')" alt="">
            {{ $t('navbar-auth.dropdown-item.3') }} </a>
          </li>
          <li v-if="$symfony.auth.boutiqueActive">
            <a :href="path('lightyshare_shop_dashboard')"><img :src="asset('img/svg/shop-icon.svg')" alt=""> {{$t('navbar-auth.dropdown-item.6')}} </a>
          </li>
          <li>
            <a :href="path('lighty_share_lighty_share_account_settings')"><img :src="asset('img/svg/setting-icon.svg')" alt=""> {{$t('navbar-auth.dropdown-item.7')}} </a>
          </li>
          <li>
            <a href="https://support.lightyshare.com/">
              <img :src="asset('img/svg/support-icon.svg')" alt="">
              {{ $t('navbar-auth.item.3') }}
            </a>
          </li>
          <li class="mobile-only">
            <a @click.prevent="openBeacon" href="#"><img :src="asset('img/svg/nous-contacter.svg')" alt="">
              {{ $t('navbar-auth.item.4') }}
            </a>
          </li>
          <li class="sign-out"><a :href="path('fos_user_security_logout')">{{$t('navbar-auth.dropdown-item.8')}}</a></li>
        </ul>
      </div>
      <!-- /.navbar-collapse -->

      <!-- /.container-fluid -->
    </nav>
    <div class="menu-overlay" :style="displayStyle" @click="toogleMenu"></div>
  </div>
  <div v-else-if="bottomMenu" class="cart-page-bottom mobile-only">
    <nav class="navbar">
      <ul class="bottom-menu">
        <li>
          <a href="#">
            <span id="hamburger-menu"><img :src="asset('img/svg/explorer-icon.svg')"></span>
            <span>Explorer</span>
          </a>
        </li>
        <li>
          <button type="button" class="navbar-toggle collapsed" data-toggle="slide-collapse" data-target="#slide-menu-collapse" aria-expanded="false" @click="toogleMenu">
            <span>
              <img :src="asset('img/svg/menu-icon.svg')">
            </span>
            <span>Menu</span>
          </button>
        </li>
      </ul>

      <div class="navbar-collapse" id="slide-menu-collapse" :style="displayStyle">
        <div style="margin: 0 0 25px 0;" class="navbar-brand">
          <img :src="asset('img/new/logo-dark.png')" alt="LightyShare" />
        </div>
        <ul class="left-menu">
          <li>
            <a class="mobile-red-button" :href="path('lighty_share_lighty_share_inscription')">
              <img :src="asset('img/svg/right-arrow-red.svg')" alt="">
              S'inscrire
            </a>
          </li>
          <li>
            <a :href="path('lighty_share_lighty_share_connexion')">
              <img :src="asset('img/svg/right-arrow-dark.svg')" alt="">
              Se connecter
            </a>
          </li>
          <li>
            <a :href="path('lighty_share_service_louez')">
              <img :src="asset('img/svg/louez-du-materiel.svg')" alt="">
              Louez du materiel
            </a>
          </li>
          <li>
            <a :href="path('lighty_share_service_rentabilisez')">
              <img :src="asset('img/svg/rentabilisez-materiel.svg')" alt="">
              Rentabilisez votre materiel
            </a>
          </li>
          <li>
            <a href="//blog.lightyshare.com"><img :src="asset('/img/svg/blog.svg')" alt="">
              Blog
            </a>
          </li>
          <li>
            <a href="//support.lightyshare.com/">
              <img :src="asset('img/svg/support-icon.svg')" alt=""> Aide </a></li>
          <li class="mobile-only"><a @click.prevent="openBeacon" href="#"><img :src="asset('img/svg/nous-contacter.svg')" alt=""> Nous contacter </a></li>
        </ul>
      </div>
      <!-- /.navbar-collapse -->

      <!-- /.container-fluid -->
    </nav>
    <div class="menu-overlay" :style="displayStyle" @click="toogleMenu">

    </div>
  </div>
</template>

<script>
import AuthNavMenu from "./AuthNavMenu.vue";
import PublicMenu from "./PublicMenu.vue"
import SearchForm from "./SearchForm.vue";
import {loadNewNotifications} from "../services/notifications";
import notifications from "../mixins/notifications";

const routes = {
  logout: '',
  contacter: '',
  support: '',
  panier: '',
  boutique: '',
  favoris: '',

}

export default {
  name: "topBar",
  mixins: [notifications],
  components: {
    PublicMenu,
    AuthNavMenu,
    SearchForm
  },
  props: {
    bottomMenu: {
      default: true
    }
  },
  data () {
    return {
      notifications: null,
      routes,
      activeMenu: false,
      isOwnerMenuActive: false
    }
  },
  async mounted() {
    if (this.user) {
      const notification = await loadNewNotifications()
      if (notification.data) {
        this.notifications = notification.data
      }
    }
  },
  computed: {
    ownerMenuClass () {

      // return thi
    },
    isSearch () {
      return this.route.path.includes('catalogue-search')
    },
    noSearch () {
      const burgerMenuUrls = [
        '/owner-settings',
        '/owner/dashboard',
        '/owner/listing',
        '/owner/rental',
        '/owner/revenus',
        '/owner/listing-update',
        '/owner/listing-creation'
      ];
      const navClass = burgerMenuUrls.reduce((hasBurger, url) => {
        return hasBurger || this.route.path.includes(url)
      }, false)
      return !navClass
    },
    useVueRouter () {
      return !!this.$route
    },
    route () {
      if (this.$route) {
        return this.$route
      } else {
        return { path: window.location.href }
      }
    },
    navClass () {
      const burgerMenuUrls = [
        '/owner-settings',
        '/owner/dashboard',
        '/owner/listing',
        '/owner/rental',
        '/owner/revenus',
        '/owner/listing-update',
        '/owner/listing-creation'
      ];
      const navClass = burgerMenuUrls.reduce((hasBurger, url) => {
          return hasBurger || this.route.path.includes(url)
      }, false)
      return navClass ? '' : 'header-new'
    },
    hasBurgerMenu () {
      const burgerMenuUrls = [
          '/owner-settings',
          '/owner/dashboard',
          '/owner/listing',
          '/owner/rental',
          '/owner/revenus'
      ];
      return burgerMenuUrls.reduce((hasBurger, url) => {
        return hasBurger || this.route.path.includes(url)
      }, false)
    },
    isAdCreation () {
      return this.route.path.includes('/owner/listing-creation') || this.route.path.includes('/owner/listing-update')
    },
    symfony () {
      return this.$symfony
    },
    user () {
      if (this.$symfony.auth && this.$symfony.auth !== 'anon.') {
        return this.$symfony.auth
      } else {
        return false
      }
    },
    granted () {
      return this.$symfony.isGranted
    },
    initials () {
      if (this.user) {
        return this.user.prenom[0] + '' + this.user.nom[0]
      } else {
        return ''
      }
    },
    profileThumb () {
      if (this.user && this.user.ImageProfil ) {
        return this.user.ImageProfil.imageName
      } else {
        return false
      }
    },
    displayStyle () {
      return this.activeMenu ? {display: 'block'} : {display: 'none'}
    }
  },
  methods: {
    openBeacon () {
      Beacon('navigate', '/ask/')
      Beacon('open')
    },
    isActive (url) {
      return this.route.path.includes(url) ? 'active' : ''
    },
    toogleMenu () {
      this.activeMenu = !this.activeMenu
    },
    toogleOwnerMenu () {
      this.isOwnerMenuActive = !this.isOwnerMenuActive
    }
  }
}
</script>

<style>

</style>
